var UserLocation = {

	initialize: async function () {
        this.coords = {}
		await UserLocation.refresh();
	},
	refresh: async function () {
		const loc = await getCurrentLocation();
        this.coords = {
            lat: loc.coords.latitude,
            lng:  loc.coords.longitude
        }
        //console.log(this.coords)
	}

}

async function getCurrentLocation() {
    if (navigator.geolocation) {
        const user_location = await getGeoLocation()
        return user_location
    } else {
        console.log("Geolocation is not supported by this browser.");
        return null
    }
}

const getGeoLocation = () => {
    return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
            position => resolve(position),
            error => reject(error),
        )
    })
}

export default UserLocation;