import {getDataFromAPI} from './roadys_api';
import UserLocation from './user-location'
import {Loader} from '@googlemaps/js-api-loader'

let x = document.getElementById("demo");

let AME, PE;

/**
 * Global variables we use to allow us to grab and modify these items as needed.
 */
let map;
let aMarkers;
let aAllLocations;
let aAllStates;
let oGoogle;
let oUserMarker;

const oStateData = [
  {
    "abbreviation": "AK",
    "state":"Alaska",
    "latitude":61.3850,
    "longitude":-152.2683
  },
  {
    "abbreviation": "AL",
    "state":"Alabama",
    "latitude":32.7990,
    "longitude":-86.8073
  },
  {
    "abbreviation": "AR",
    "state":"Arkansas",
    "latitude":34.9513,
    "longitude":-92.3809
  },
  {
    "abbreviation": "AZ",
    "state":"Arizona",
    "latitude":33.7712,
    "longitude":-111.3877
  },
  {
    "abbreviation": "CA",
    "state":"California",
    "latitude":36.1700,
    "longitude":-119.7462
  },
  {
    "abbreviation": "CO",
    "state":"Colorado",
    "latitude":39.0646,
    "longitude":-105.3272
  },
  {
    "abbreviation": "CT",
    "state":"Connecticut",
    "latitude":41.5834,
    "longitude":-72.7622
  },
  {
    "abbreviation": "DE",
    "state":"Delaware",
    "latitude":39.3498,
    "longitude":-75.5148
  },
  {
    "abbreviation": "DC",
    "state":"District of Columbia",
    "latitude":38.9072,
    "longitude":-77.0369
  },
  {
    "abbreviation": "FL",
    "state":"Florida",
    "latitude":27.8333,
    "longitude":-81.7170
  },
  {
    "abbreviation": "GA",
    "state":"Georgia",
    "latitude":32.9866,
    "longitude":-83.6487
  },
  {
    "abbreviation": "HI",
    "state":"Hawaii",
    "latitude":21.1098,
    "longitude":-157.5311
  },
  {
    "abbreviation": "IA",
    "state":"Iowa",
    "latitude":42.0046,
    "longitude":-93.2140
  },
  {
    "abbreviation": "ID",
    "state":"Idaho",
    "latitude":44.2394,
    "longitude":-114.5103
  },
  {
    "abbreviation": "IL",
    "state":"Illinois",
    "latitude":40.3363,
    "longitude":-89.0022
  },
  {
    "abbreviation": "IN",
    "state":"Indiana",
    "latitude":39.8647,
    "longitude":-86.2604
  },
  {
    "abbreviation": "KA",
    "state":"Kansas",
    "latitude":38.5111,
    "longitude":-96.8005
  },
  {
    "abbreviation": "KY",
    "state":"Kentucky",
    "latitude":37.6690,
    "longitude":-84.6514
  },
  {
    "abbreviation": "LA",
    "state":"Louisiana",
    "latitude":31.1801,
    "longitude":-91.8749
  },
  {
    "abbreviation": "MA",
    "state":"Massachusetts",
    "latitude":42.2373,
    "longitude":-71.5314
  },
  {
    "abbreviation": "MD",
    "state":"Maryland",
    "latitude":39.0724,
    "longitude":-76.7902
  },
  {
    "abbreviation": "ME",
    "state":"Maine",
    "latitude":44.6074,
    "longitude":-69.3977
  },
  {
    "abbreviation": "MI",
    "state":"Michigan",
    "latitude":43.3504,
    "longitude":-84.5603
  },
  {
    "abbreviation": "MN",
    "state":"Minnesota",
    "latitude":45.7326,
    "longitude":-93.9196
  },
  {
    "abbreviation": "MO",
    "state":"Missouri",
    "latitude":38.4623,
    "longitude":-92.3020
  },
  {
    "abbreviation": "MS",
    "state":"Mississippi",
    "latitude":32.7673,
    "longitude":-89.6812
  },
  {
    "abbreviation": "MT",
    "state":"Montana",
    "latitude":46.9048,
    "longitude":-110.3261
  },
  {
    "abbreviation": "NC",
    "state":"North Carolina",
    "latitude":35.6411,
    "longitude":-79.8431
  },
  {
    "abbreviation": "ND",
    "state":"North Dakota",
    "latitude":47.5362,
    "longitude":-99.7930
  },
  {
    "abbreviation": "NE",
    "state":"Nebraska",
    "latitude":41.1289,
    "longitude":-98.2883
  },
  {
    "abbreviation": "NH",
    "state":"New Hampshire",
    "latitude":43.4108,
    "longitude":-71.5653
  },
  {
    "abbreviation": "NJ",
    "state":"New Jersey",
    "latitude":40.3140,
    "longitude":-74.5089
  },
  {
    "abbreviation": "NM",
    "state":"New Mexico",
    "latitude":34.8375,
    "longitude":-106.2371
  },
  {
    "abbreviation": "NV",
    "state":"Nevada",
    "latitude":38.4199,
    "longitude":-117.1219
  },
  {
    "abbreviation": "NY",
    "state":"New York",
    "latitude":42.1497,
    "longitude":-74.9384
  },
  {
    "abbreviation": "OH",
    "state":"Ohio",
    "latitude":40.3736,
    "longitude":-82.7755
  },
  {
    "abbreviation": "OK",
    "state":"Oklahoma",
    "latitude":35.5376,
    "longitude":-96.9247
  },
  {
    "abbreviation": "OR",
    "state":"Oregon",
    "latitude":44.5672,
    "longitude":-122.1269
  },
  {
    "abbreviation": "PA",
    "state":"Pennsylvania",
    "latitude":40.5773,
    "longitude":-77.2640
  },
  {
    "abbreviation": "RI",
    "state":"Rhode Island",
    "latitude":41.6772,
    "longitude":-71.5101
  },
  {
    "abbreviation": "SC",
    "state":"South Carolina",
    "latitude":33.8191,
    "longitude":-80.9066
  },
  {
    "abbreviation": "SD",
    "state":"South Dakota",
    "latitude":44.2853,
    "longitude":-99.4632
  },
  {
    "abbreviation": "TN",
    "state":"Tennessee",
    "latitude":35.7449,
    "longitude":-86.7489
  },
  {
    "abbreviation": "TX",
    "state":"Texas",
    "latitude":31.1060,
    "longitude":-97.6475
  },
  {
    "abbreviation": "UT",
    "state":"Utah",
    "latitude":40.1135,
    "longitude":-111.8535
  },
  {
    "abbreviation": "VA",
    "state":"Virginia",
    "latitude":37.7680,
    "longitude":-78.2057
  },
  {
    "abbreviation": "VT",
    "state":"Vermont",
    "latitude":44.0407,
    "longitude":-72.7093
  },
  {
    "abbreviation": "WA",
    "state":"Washington",
    "latitude":47.3917,
    "longitude":-121.5708
  },
  {
    "abbreviation": "WI",
    "state":"Wisconsin",
    "latitude":44.2563,
    "longitude":-89.6385
  },
  {
    "abbreviation": "WV",
    "state":"West Virginia",
    "latitude":38.4680,
    "longitude":-80.9696
  },
  {
    "abbreviation": "WY",
    "state":"Wyoming",
    "latitude":42.7475,
    "longitude":-107.2085
  }
];

/**
 * Loads the places library, specifying the key and version to use.
 * @type {Loader}
 */
const MapLoader = new Loader({
    apiKey: 'AIzaSyC2fj3n85KSEdNTzGkJlFjhzue0uvhrBPw',
    version: 'weekly',
    libraries: ['places'],
    async: true,
})

/**
 * Removes all child nodes of the specified parent.
 * @param parent
 */
function removeAllChildNodes(parent) {
    while (parent.firstChild) {
        parent.removeChild(parent.firstChild);
    }
}

/**
 * Sets up the location title bullet item
 * @returns {HTMLSpanElement}
 */
const locationTitleBullet = (bTextResponse = false) => {
  let bullet;
  if (bTextResponse) {
    bullet = '<span class="bullet">&bull;</span>';
  } else {
    bullet = document.createElement("span")
    bullet.classList.add("bullet")
    bullet.innerHTML = '&bull;'
  }
  return bullet
}

/**
 * Sets up default currency options.
 * @type {{style: string, currency: string}}
 */
const currencyOptions = {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 3,
    maximumFractionDigits: 3,
}

/**
 * Formats the location amenities.
 * @param amenities
 * @param multiplier
 * @returns {HTMLDivElement}
 * @constructor
 */
const LocationAmenities = (amenities,multiplier) => {
  const locationAmenityIcons = document.createElement("div")
  locationAmenityIcons.classList.add("icons")

  amenities.forEach(a => {
    const locationAmenityIcon = document.createElement("i")
    locationAmenityIcon.classList.add("me-1")

    //Check for Rewards
    if (a.amenity_id === 54 && a.status[0] > 0) { // Showers
        locationAmenityIcon.classList.add("fas", "fa-shower")
    } else if (a.amenity_id === 24 && a.status[0] > 0) {  // Parking Spaces
        locationAmenityIcon.classList.add("fas", "fa-parking")
        const iconVal = document.createElement("span")
        iconVal.appendChild(document.createTextNode(' ' + a.status[0]))
        locationAmenityIcon.appendChild(iconVal)
    } else if (a.amenity_id === 35 && a.status[0] === true) { // Dine-In Restraunt
        locationAmenityIcon.classList.add("fas", "fa-utensils")
    } else if (a.amenity_id === 43 && a.status[0] > 0) { // Diesel Pumps
        locationAmenityIcon.classList.add("fas", "fa-gas-pump")
    } else if (a.amenity_id === 16 && a.status[0] === true) { // WiFi
        locationAmenityIcon.classList.add("fas", "fa-wifi")
    } else if (a.amenity_id === 20 && a.status[0] === 'Onsite') { // Hotel
        locationAmenityIcon.classList.add("fas", "fa-bed")
    } else if (a.amenity_id === 91 && a.status[0] === true) { // Oil Change Service
        locationAmenityIcon.classList.add("fas", "fa-oil-can")
    } else if (a.amenity_id === 38 && a.status.length > 0) { // Fast Food
        locationAmenityIcon.classList.add("fas", "fa-hamburger")
    } else if (a.amenity_id === 181 && a.status[0] === true) { // Service Center
        locationAmenityIcon.classList.add("fas", "fa-wrench")
    } else if (a.amenity_id === 116 && a.status[0] === true) { // DEF
        locationAmenityIcon.classList.add("fas", "def")
    }

    if (locationAmenityIcon.classList.contains("fas"))
    {
        locationAmenityIcons.appendChild(locationAmenityIcon)
    }
  })

	if (multiplier !== "NONE") {
		const locationAmenityIcon = document.createElement("i")
		locationAmenityIcon.classList.add("me-1", "fas")

    const locationAmenityIconChild = document.createElement("span");
    locationAmenityIconChild.classList.add("rewards-badge-front");
    locationAmenityIconChild.textContent = "REWARDS";

		if (multiplier !== "1X") {
			const multiplier_num = multiplier.replace('X', '');
			const iconVal = document.createElement("span")
            iconVal.appendChild(document.createTextNode(" x" + multiplier_num))
            locationAmenityIconChild.appendChild(iconVal)
		} else {
			const iconVal = document.createElement("span")
            iconVal.appendChild(document.createTextNode(""))
            locationAmenityIconChild.appendChild(iconVal)
		}
    locationAmenityIcon.appendChild(locationAmenityIconChild)
		locationAmenityIcons.appendChild(locationAmenityIcon)
	}
  return locationAmenityIcons
}

/**
 * Full amenities builder - returns an array of categories with the amenities markup already tossed in.
 * @param amenities
 * @param multiplier
 * @returns {{}}
 * @constructor
 */
const LocationCategorizedAmenitiesDescriptions = (amenities, multiplier) => {
  const aCategorized = {};
  const aCategorizedMarkup = {};

  // Create a categorized list of all amenities, grouped by type.
  amenities.forEach((oAmenity) => {
    const sType = oAmenity.type.trim();

    // If this is a new category, initialize the new array!
    if (!Array.isArray(aCategorized[sType])) {
      aCategorized[sType] = [];
    }

    // Push the amenity into the array.
    aCategorized[sType].push(oAmenity);
  });


  // Sort through the categorized amenities and build their display(s).
  Object.keys(aCategorized).forEach((sCategory) => {
    // Set up the category header.
    const categoryContainer = document.createElement('div');


    // This will hold all our amenities
    const locationAmenityLines = document.createElement("div")

    // Loop through the categories' amenities.
    const aAmenities = aCategorized[sCategory];
    aAmenities.forEach((a) => {
      const amenityLine = document.createElement('div');
      amenityLine.classList.add('text-muted');
      const icon = document.createElement('i');
      const description = document.createElement('div');
      description.classList.add('description');
      icon.classList.add('me-1');

      //Check for Rewards
      if (a.amenity_id === 54 && a.status[0] > 0) { // Showers
        icon.classList.add("fas", "fa-shower")
        description.appendChild(icon)
        description.appendChild(document.createTextNode("Showers Available"))
        amenityLine.appendChild(description)
      } else if (a.amenity_id === 24 && a.status[0] > 0) {  // Parking Spaces
        icon.classList.add("fas", "fa-parking")
        description.appendChild(icon)
        description.appendChild(document.createTextNode(a.status[0] + " Parking Spaces"))
        amenityLine.appendChild(description)
      } else if (a.amenity_id === 35 && a.status[0] === true) { // Dine-In Restaurant
        icon.classList.add("fas", "fa-utensils")
        description.appendChild(icon)
        description.appendChild(document.createTextNode("Dine-In Restaurant"))
        amenityLine.appendChild(description)
      } else if (a.amenity_id === 43 && a.status[0] > 0) { // Diesel Pumps
        icon.classList.add("fas", "fa-gas-pump")
        description.appendChild(icon)
        description.appendChild(document.createTextNode("Diesel Pumps"))
        amenityLine.appendChild(description)
      } else if (a.amenity_id === 16 && a.status[0] === true) { // WiFi
        icon.classList.add("fas", "fa-wifi")
        description.appendChild(icon)
        description.appendChild(document.createTextNode("Wi-Fi"))
        amenityLine.appendChild(description)
      } else if (a.amenity_id === 20 && a.status[0] === 'Onsite') { // Hotel
        icon.classList.add("fas", "fa-bed")
        description.appendChild(icon)
        description.appendChild(document.createTextNode("Hotel"))
        amenityLine.appendChild(description)
      } else if (a.amenity_id === 91 && a.status[0] === true) { // Oil Change Service
        icon.classList.add("fas", "fa-oil-can")
        description.appendChild(icon)
        description.appendChild(document.createTextNode("Oil Change Service"))
        amenityLine.appendChild(description)
      } else if (a.amenity_id === 38 && a.status.length > 0) { // Fast Food
        icon.classList.add("fas", "fa-hamburger")
        description.appendChild(icon)
        description.appendChild(document.createTextNode("Fast Food"))
        amenityLine.appendChild(description)
      } else if (a.amenity_id === 181 && a.status[0] === true) { // Service Center
        icon.classList.add("fas", "fa-wrench")
        description.appendChild(icon)
        description.appendChild(document.createTextNode("Service Center"))
        amenityLine.appendChild(description)
      } else if (a.amenity_id === 116 && a.status[0] === true) { // DEF
        icon.classList.add("fas", "def")
        description.appendChild(icon)
        description.appendChild(document.createTextNode("DEF Diesel"))
        amenityLine.appendChild(description)
      } else {
        icon.classList.add("fas")
        description.appendChild(icon)
        description.appendChild(document.createTextNode(a.name))
        amenityLine.appendChild(description)
      }

      if (icon.classList.contains("fas"))
      {
        locationAmenityLines.appendChild(amenityLine)
      }
      categoryContainer.appendChild(locationAmenityLines);
    });

    // Let's toss this at the end of the Financial section.
    if (sCategory === 'Financial') {
      // Handle adding multiplier stuff to rewards.
      if (multiplier !== "NONE") {
        const amenityLine = document.createElement("div")
        const icon = document.createElement("i")
        const description = document.createElement("div")

        description.classList.add("description");
        // icon.classList.add("me-1")
        // icon.classList.add("fas", "rewards");
        const badge = document.createElement("span");
        badge.classList.add("rewards-badge");
        badge.textContent = "REWARDS";
        icon.appendChild(badge);

        if (multiplier !== "1X") {
          const multiplier_num = multiplier.replace('X', '');
          description.appendChild(icon)
          description.appendChild(document.createTextNode("Roady's Rewards Multiplier x" + multiplier_num))
          amenityLine.appendChild(description)
        } else {
          description.appendChild(icon)
          description.appendChild(document.createTextNode("Roady's Rewards"))
          amenityLine.appendChild(description)
        }
        amenityLine.classList.add('text-muted');
        categoryContainer.appendChild(amenityLine)
      }
    }
    if (!Array.isArray(aCategorizedMarkup[sCategory])) {
      aCategorizedMarkup[sCategory] = [];
    }
    aCategorizedMarkup[sCategory].push(categoryContainer);
  });

  return aCategorizedMarkup;
}


/**
 * Amenities descriptions items.
 * @param amenities
 * @param multiplier
 * @returns {HTMLDivElement}
 * @constructor
 */
const LocationAmenitiesDescriptions = (amenities,multiplier) => {	
  const locationAmenityLines = document.createElement("div")
	const row = document.createElement("div")
	row.appendChild(document.createTextNode("Amenities"))
	locationAmenityLines.appendChild(row);

  if (multiplier !== "NONE") {
    const amenityLine = document.createElement("div")
    const icon = document.createElement("i")
    const badge = document.createElement("span");
    const description = document.createElement("div")

    description.classList.add("description");
    badge.classList.add("rewards-badge");
    badge.textContent = "REWARDS";
    icon.appendChild(badge);

    if (multiplier !== "1X") {
      const multiplier_num = multiplier.replace('X', '');
      description.appendChild(icon)
      description.appendChild(document.createTextNode("Roady's Rewards Multiplier x" + multiplier_num))
      amenityLine.appendChild(description)
    } else {
      description.appendChild(icon)
      description.appendChild(document.createTextNode("Roady's Rewards"))
      amenityLine.appendChild(description)
    }
    locationAmenityLines.appendChild(amenityLine)
  }

  amenities.forEach(a => {
    const amenityLine = document.createElement("div")
    const icon = document.createElement("i")
    const description = document.createElement("div")
    description.classList.add("description");
    icon.classList.add("me-1")

    //Check for Rewards
    if (a.amenity_id === 54 && a.status[0] > 0) { // Showers
      icon.classList.add("fas", "fa-shower")
      description.appendChild(icon)
      description.appendChild(document.createTextNode("Showers Available"))
      amenityLine.appendChild(description)
    } else if (a.amenity_id === 24 && a.status[0] > 0) {  // Parking Spaces
      icon.classList.add("fas", "fa-parking")
      description.appendChild(icon)
      description.appendChild(document.createTextNode(a.status[0] + " Parking Spaces"))
      amenityLine.appendChild(description)
    } else if (a.amenity_id === 35 && a.status[0] === true) { // Dine-In Restaurant
      icon.classList.add("fas", "fa-utensils")
      description.appendChild(icon)
      description.appendChild(document.createTextNode("Dine-In Restaurant"))
      amenityLine.appendChild(description)
    } else if (a.amenity_id === 43 && a.status[0] > 0) { // Diesel Pumps
      icon.classList.add("fas", "fa-gas-pump")
      description.appendChild(icon)
      description.appendChild(document.createTextNode("Diesel Pumps"))
      amenityLine.appendChild(description)
    } else if (a.amenity_id === 16 && a.status[0] === true) { // WiFi
      icon.classList.add("fas", "fa-wifi")
      description.appendChild(icon)
      description.appendChild(document.createTextNode("Wi-Fi"))
      amenityLine.appendChild(description)
    } else if (a.amenity_id === 20 && a.status[0] === 'Onsite') { // Hotel
      icon.classList.add("fas", "fa-bed")
      description.appendChild(icon)
      description.appendChild(document.createTextNode("Hotel"))
      amenityLine.appendChild(description)
    } else if (a.amenity_id === 91 && a.status[0] === true) { // Oil Change Service
      icon.classList.add("fas", "fa-oil-can")
      description.appendChild(icon)
      description.appendChild(document.createTextNode("Oil Change Service"))
      amenityLine.appendChild(description)
    } else if (a.amenity_id === 38 && a.status.length > 0) { // Fast Food
      icon.classList.add("fas", "fa-hamburger")
      description.appendChild(icon)
      description.appendChild(document.createTextNode("Fast Food"))
      amenityLine.appendChild(description)
    } else if (a.amenity_id === 181 && a.status[0] === true) { // Service Center
      icon.classList.add("fas", "fa-wrench")
      description.appendChild(icon)
      description.appendChild(document.createTextNode("Service Center"))
      amenityLine.appendChild(description)
    } else if (a.amenity_id === 116 && a.status[0] === true) { // DEF
      icon.classList.add("fas", "def")
      description.appendChild(icon)
      description.appendChild(document.createTextNode("DEF Diesel"))
      amenityLine.appendChild(description)
    }

    if (icon.classList.contains("fas"))
    {
      locationAmenityLines.appendChild(amenityLine)
    }
  })

  // If there is only 1 amenities line, add a message.
  if (locationAmenityLines.children.length === 1) {
    const amenityLineText = '<div><div class="description"><i class="me-1 fas fa-ban"></i>No amenities listed.</div></div>';
    locationAmenityLines.appendChild(htmlToElement(amenityLineText));
  }


  return locationAmenityLines
}

/**
 * Given a location type, will return the path to an icon to use.
 * @param type
 * @returns {string}
 */
function getPTPIconFromType(type) {
  let sWebRoot = PRWebRoot();
  return sWebRoot + '/assets/img/map_icons/ptp_1.png';
}

/**
 * Given a location type, will return the path to an icon to use.
 * @param type
 * @returns {string}
 */
function getRoadysIconFromType(type) {
  return getIconFromType(type);
}

/**
 * Given a location type, will return the path to an icon to use.
 * @param type
 * @returns {string}
 */
function getIconFromType(type) {
  let sReturn;
  let sWebRoot = PRWebRoot();
  switch (type) {
    case "V": // Service Center
      sReturn = sWebRoot + '/assets/img/map_icons/icon_gmaps_blkr.png';
      break;

    case "C": // C-Store
      sReturn = sWebRoot + '/assets/img/map_icons/icon_gmaps_yelr.png';
      break;

    case "F": // Fuel Stop
    case "R": // Truck Stop
      sReturn = sWebRoot + '/assets/img/map_icons/icon_gmaps_redr.png';
      break;

    case "S": // Truck Stop // Service Center
      sReturn = sWebRoot + '/assets/img/map_icons/icon_gmaps_blur.png';
      break;

    case "D": // DFS
    default:
      sReturn = '';
  }
  return sReturn;
}

/**
 * Given a location group, will return the path to an icon to use.
 * @param sGroup
 * @returns {*}
 */
function renderGroupBadge(sGroup) {
  let sReturn = '';
  switch (sGroup) {
    case "R":
      sReturn = '<span class="group-badge" style="background-color: #3565a4; color: #ffffff;">R</span>';
      break;

    case "P":
      sReturn = '<span class="group-badge" style="background-color: #1a9280; color: #ffffff;">P</span>';
      break;

    case "D":
      sReturn = '<span class="group-badge" style="background-color: #1a1a1a; color: #FFA800;">D</span>';
      break;

    default:
      sReturn = '';
      break;
  }
  return sReturn;
}

/**
 * Given a location, will return the icon to use.
 * @param location
 * @returns {string}
 */
function renderIconByLocation(location) {
  let sReturn = '';
  let sIconPath = '';
  let sGroup = location.group;
  let sType = location.type;
  switch (sGroup) {
    case "R":
      sIconPath = getRoadysIconFromType(sType);
      break;

    case "P":
      sIconPath = getPTPIconFromType(sType);
      break;

    case "D":
    default:
      sIconPath = getIconFromType(sType);
      break;
  }
  sReturn = '<img alt="icon" src="' + sIconPath + '" height="15px" width="20px" style="margin-right: 3px;" />';
  return sReturn;
}

/**
 * Gets the location name from the type.
 * @param type
 * @returns {string}
 */
function getLocationTypeNameFromType(type) {
  let sReturn;

  switch (type) {
    case "V":
      sReturn =  "Service Center";
      break;

    case "C":
      sReturn = "C-Store";
      break;

    case "F":
      sReturn = "Fuel Stop";
      break;

    case "R":
      sReturn = "Truck Stop";
      break;

    case "S":
      sReturn = "Truck Stop / Service Center";
      break;

    case "D":
    default:
      sReturn = 'Listed type: ' +  type;
      break;
  }
  return sReturn;
}

/**
 * Gets the location group name from the group.
 * @param sGroup
 * @returns {string}
 */
function getLocationGroupNameFromGroup(sGroup) {
  let sReturn = '';
  switch (sGroup) {
    case "R":
      sReturn = "Roady's";
      break;

    case "D":
      sReturn = "DFS";
      break;

    case "P":
      sReturn = "PTP";
      break;
  }
  return sReturn;
}

/**
 * Turns html markup into an element html node
 * @param html
 * @returns {ChildNode}
 */
function htmlToElement(html) {
  let template = document.createElement('template');
  html = html.trim();
  template.innerHTML = html;
  return template.content.firstChild;
}

/**
 * Converts an html node into a string representation / html markup..
 * @param node
 * @returns {string}
 */
function elementToHtml ( node ) {
  let tmpNode = document.createElement( "div" );
  tmpNode.appendChild( node.cloneNode( true ) );
  let str = tmpNode.innerHTML;
  tmpNode = node = null; // prevent memory leaks in IE
  return str;
}

/**
 * Creates the location title that gets used for the location panels on the accordions.
 * @param location
 * @param amenities
 * @returns {HTMLHeadingElement}
 */
const locationTitle = (location, amenities) => {
  const { location_name, location_id, address, city, state, zip, retail_price, distance, multiplier, type, group } = location

  // Create the icon markup
  let sIconMarkup = '',
      sIconSource = getIconFromType(type);
  if (sIconSource) {
    sIconMarkup ='<img alt="icon" src="' + getIconFromType(type) + '" height="12px" /> ';
  }

  // Create the first row segment.
  const segmentFirstRow = '<div class="row">' +
    '<div class="col p-0">' +
    // sIconMarkup
    renderGroupBadge(group) +
    // renderIconByLocation(location) +
    location_name +
    '</div>' +
    '</div>';

  // Create the price segment.
  let segmentPrice = '';
  const price = new Intl.NumberFormat('default', currencyOptions).format(retail_price)
  if (!(price === "$0.00")) {
    segmentPrice = locationTitleBullet(true) + ' ' + price + ' <i class="fas fa-gas-pump" />';
  }

  // Create the distance segment.
  let segmentDistance = '';
  if (distance) {
    segmentDistance = parseInt(distance) + ' mi ' + locationTitleBullet(true) + ' ';
  }

  // Create the second row segment.
  const segmentSecondRow = '<div class="row">' +
    '<div class="col p-0">' +
    segmentDistance + city + ', ' + state + segmentPrice +
    '</div>' +
    '</div>';

  // Put the segments together to create the button markup.
  const segmentFullRow = '<div class="container-fluid" style="float: left">' + segmentFirstRow + segmentSecondRow + "</div>";
  const titleButtonMarkup = '<button type="button" class="accordion-button collapsed" data-bs-Toggle="collapse" data-bs-Target="#panelsStayOpen-collapse' + location_id + '" data-aria-expanded="false" data-aria-controls="panelStayOpen-collapse' + location_id + '">' +
    segmentFullRow +
  '</button>';

  // Create the title button from the button markup.
  const locationTitleButton = htmlToElement(titleButtonMarkup);

  // Create the location title element.
  const locationTitle = document.createElement("h6")
  locationTitle.classList.add("accordion-header", "location")
  locationTitle.appendChild(locationTitleButton)

  const locationAmenityRow = document.createElement("div")
  locationAmenityRow.classList.add("row")

  const locationAmenityCol = document.createElement("div")
  locationAmenityCol.classList.add("col-10")

  locationAmenityCol.appendChild(LocationAmenities(amenities,multiplier))
  locationAmenityRow.appendChild(locationAmenityCol)

  // Create the location arrow column.
  const locationArrowCol = document.createElement("div")
  locationArrowCol.classList.add("col-2", "text-end")

  // Create the location nav button
  const locLink = "https://www.google.com/maps/dir/Current+Location/" + address + "+" + city + "+" + state + "+" + zip
  const locationNavButton = document.createElement("a")
  locationNavButton.setAttribute("href", locLink);
  locationNavButton.setAttribute("role", "button");
  locationNavButton.classList.add("btn", "btn-primary", "btn-xs")

  // Create the nav icon.
  const locationNavIcon = document.createElement("i")
  locationNavIcon.classList.add("fas", "fa-location-arrow")

  // Append all the things to the title.
  locationNavButton.appendChild(locationNavIcon)
  locationArrowCol.appendChild(locationNavButton)
  locationAmenityRow.appendChild(locationArrowCol)
  locationTitle.appendChild(locationAmenityRow)

  return locationTitle
}

/**
 * Creates the location panel that gets appended to the accordions
 * @param location_data
 * @param amenities
 * @returns {HTMLDivElement}
 */
const locationPanel = (location_data,amenities) => {
  const { location_id, location, location_name, city, state, retail_price, distance, latitude, longitude, multiplier, type } = location_data

  const panel = document.createElement("div")
  panel.id = "panelsStayOpen-collapse" + location_id
  panel.classList.add("accordion-collapse", "collapse")
  panel.setAttribute('aria-labelledby', "panelsStayOpen-heading" + location_id)

  const body = document.createElement("div")
  body.classList.add("accordion-body")
	body.appendChild(LocationAmenitiesDescriptions(amenities,multiplier))

  const row = document.createElement("div")
  row.classList.add("row")

  const col1 = document.createElement("div")
  col1.classList.add("col-7")
  //col1.appendChild(document.createTextNode(location_name))
  col1.appendChild(document.createElement('br'));
  col1.appendChild(document.createTextNode(getLocationTypeNameFromType(type)));
	row.appendChild(col1)

  const col2 = document.createElement("div")
  col2.classList.add("col-7")
  col2.appendChild(document.createTextNode(location))
  row.appendChild(col2)

  // Adds link to full profile.
  const oProfileLink = document.createElement('a');
  oProfileLink.setAttribute('href', 'location.html?id=' + location_id);
  const oProfileLinkText = document.createElement('span');
  oProfileLinkText.innerHTML = "View location details &rarr;";
  oProfileLink.appendChild(oProfileLinkText);
  row.appendChild(oProfileLink);

  const col3 = document.createElement("div")
  col3.classList.add("col-5", "text-end")
  row.appendChild(col3)
  body.appendChild(row)
  panel.appendChild(body)

  return panel
}

/**
 * Format the specified location and append it to the localRoadys widget on the front page.
 * @param location
 * @returns {HTMLDivElement}
 */
const formatLocation = (location) => {
  const newLocationDiv = document.createElement("div")
  newLocationDiv.classList.add("accordion-item", "location")
  newLocationDiv.appendChild(locationTitle(location, location.amenities))
  newLocationDiv.appendChild(locationPanel(location, location.amenities))
  const oLocalRoadys = document.getElementById('localRoadys');
  if (oLocalRoadys) {
    oLocalRoadys.appendChild(newLocationDiv);
  }

  //remove message to allow location
	let locationMsg = document.getElementById("loc-msg");
	if (locationMsg) {
    locationMsg.style.display = "none";
  }

  return newLocationDiv
}

/**
 * Gets a location data from api based on user location
 * @param userLoc
 * @returns {Promise<void>}
 */
async function getLocation(userLoc) {
  const location_radius = 200
  if (userLoc.coords.lat && userLoc.coords.lng)
  {
    await getDataFromAPI('locations/near/' + userLoc.coords.lat + '/' + userLoc.coords.lng + '/' + location_radius).then(async (locations) => {
      await removeKnownLocations(locations.data.locations).then((aLocations) => {
        const locs = aLocations.filter((loc, index) => index < 3);
        locs.forEach((l, index) => {
          formatLocation(l);
        });
      });
    });
  }
}

/**
 * Gets data for a single location and returns it.
 * @param nLocationId
 * @returns {Promise<void>}
 */
async function getSingleLocation(nLocationId) {
  return await getDataFromAPI('location/' + nLocationId);
}

/**
 * Accepts a list of locations and filters them.  Only returns locations that are public-enabled, and are either Roady's or PTP.
 * @param filteredResponse
 * @returns {*}
 */
async function removeKnownLocations(filteredResponse) {
  return filteredResponse.filter(oItem =>
    (oItem.group.toUpperCase() === 'R' || oItem.group.toUpperCase() === 'P') && oItem.options.public.enabled
  );
}

/**
 * Builds an array of all the found states from a list of locations.
 * @param aLocations
 * @returns {*[]}
 */
function getStatesFromLocations(aLocations) {
  let aStates = [];
  aLocations.forEach((oLocation) => {
    let sLocationState = oLocation.state.toUpperCase();
    if (!aStates.includes(sLocationState)) {
      aStates.push(sLocationState);
    }
  })
  return aStates.sort();
}

/**
 * Gets all locations, based on specified filters if available, creates map.
 * @param UserLocation
 * @param oFilters
 */
async function getLocationsByFilter(UserLocation, oFilters) {

  // If we don't have the aAllLocations global set, lets fill it first.
  let aLocations;
  if (!aAllLocations) {
    await getDataFromAPI('locations?show_all=true').then(async (locations) => {
      await removeKnownLocations(locations.data.locations).then((aLocations) => {
        aAllLocations = aLocations;
        aAllStates = getStatesFromLocations(aLocations);
      });
    })
  }

  // Grab it from the global, which should be full one way or another now.
  aLocations = aAllLocations
  let filteredResponse = aLocations;

  // If we have filters, lets handle those.
  if (oFilters) {
    // filter out states that don't match if we have a state set.
    if (oFilters.state !== 'all') {
      filteredResponse = filteredResponse.filter((oItem) => {
        if (oItem.state.toUpperCase() === oFilters.state.toUpperCase()) {
          return true;
        }
      })
    }

    // If we have a location_type filter, we filter by that type.
    if (oFilters.location_type !== 'all') {
      filteredResponse = filteredResponse.filter((oItem) => {
        if (oItem.type.toUpperCase() === oFilters.location_type.toUpperCase()) {
          return true;
        }
      });
    }

    // If we have a location_group filter, we filter by that group.
    if (oFilters.location_group !== 'all') {
      filteredResponse = filteredResponse.filter((oItem) => {
        if (oItem.group.toUpperCase() === oFilters.location_group.toUpperCase()) {
          return true;
        }
      });
    }
  }

  // Put the final filtered response in outer scope so that we can return it.
  return filteredResponse;
}

/**
 * Gets all locations and creates a map.
 * @param UserLocation
 * @param sResultsContainer
 * @returns {Promise<void>}
 */
async function getAllLocations(UserLocation, sResultsContainer) {
  setContainerContent('map', 'LOADING, PLEASE WAIT......', 'text');
  let path;

  // If there is no UserLocation coordinates to use, use the alternative api query.
  if (UserLocation.coords?.lat && UserLocation.coords?.lng) {
    let radius = 4000;
    path = 'locations/near/' + UserLocation.coords.lat + '/' + UserLocation.coords.lng + '/' + radius;
  } else {
    path = 'locations?show_all=true';
  }

  await getDataFromAPI(path).then(async (locations) => {
    await removeKnownLocations(locations.data.locations).then(async (aLocations) => {
      // Updates the global states list.
      aAllLocations = aLocations; // Set the global locations variable.
      aAllStates = getStatesFromLocations(aLocations);
      await createMap(UserLocation, aLocations).then(async () => {
        let element = document.getElementById("map_page_filters");
        element.classList.remove("d-none");
        await updateLocationsResponse(aLocations, sResultsContainer).then(() => {
          displayMapResultsMessage(aLocations);
        });
      });
    });
  });
}

/**
 * Sets the content of a container.
 * @param sContainerId
 * @param sContent
 * @param type
 */
function setContainerContent(sContainerId, sContent, type='text') {
  let oElement = document.getElementById(sContainerId);
  switch (type) {
    case 'html':
      oElement.innerHTML = sContent;
      break;

    case 'text':
    default:
      oElement.innerText = sContent;
        break;
  }

}

/**
 * Creates a map with parameters.
 * @param userLoc  // The user's location
 * @param locs     // The array of locations to display as markers.
 * @param aOptions // Optional options
 * @returns {Promise<*>}
 */
async function createMap(userLoc, locs, aOptions = {}){
    aMarkers = [];

    MapLoader
    .load()
    .then(async (google) => {
      oGoogle = google;

      const { AdvancedMarkerElement, PinElement } = await google.maps.importLibrary(
        "marker",
      );

      AME = AdvancedMarkerElement;
      PE = PinElement;

      let myLatlng;

      if (userLoc.coords) {
        if (userLoc.coords.lat && userLoc.coords.lng) {
          myLatlng = new google.maps.LatLng(userLoc.coords.lat, userLoc.coords.lng); // Map center coodinates
        }
      }

      var mapOptions = {
        zoom: 8, // Initial zoom level (0-20)
        //styles: [{"featureType":"all","stylers":[{"saturation":0},{"hue":"#e7ecf0"}]},{"featureType":"road","stylers":[{"saturation":-70}]},{"featureType":"transit","stylers":[{"visibility":"off"}]},{"featureType":"poi","stylers":[{"visibility":"off"}]},{"featureType":"water","stylers":[{"visibility":"simplified"},{"saturation":-60}]}], // Source: https://snazzymaps.com/style/15/subtle-grayscale
        // center: myLatlng, // Centre the Map to our coordinates variable
        mapTypeId: google.maps.MapTypeId.ROADMAP, // Set the type of Map
        minZoom: 4, // Minimum zoom level allowed (0-20)
        maxZoom: 20, // Maximum soom level allowed (0-20)
        zoomControl: true, // Set to true if using zoomControlOptions below, or false to remove all zoom controls.
        zoomControlOptions: {
          style: google.maps.ZoomControlStyle.DEFAULT // Change to SMALL to force just the + and - buttons.
        },
        // 'true' is default value for all controls below
        panControl: true, // Set to false to disable
        mapTypeControl: true, // Disable Map/Satellite switch
        scaleControl: true, // Set to false to hide scale
        streetViewControl: true, // Set to disable to hide street view
        overviewMapControl: false, // Set to false to remove overview control
        rotateControl: true, // Set to false to disable rotate control
        mapId: 'map',
      };

      if (aOptions.map) {
        map = aOptions.map
      } else {
        map = new google.maps.Map(document.getElementById('map'), mapOptions);
      }

      oUserMarker = new AME({
        position: myLatlng,
        map,
        title: "You",
      });

      // Adds the markers for all the locations, then updates the map bounds.
      await addMarkersForLocations(locs).then(async() => {
        await updateBounds(myLatlng);
      });
    })
    .catch(e => {
      console.log("Caught createMap exception: ", e);
        // do something
    });

	return map;
}

/**
 * Given a state abbreviation, will return an object containing lat and lng coordinates.
 * @param sState
 * @returns {{lng: *, lat: *}}
 */
function getStateCoordinates(sState) {
  let oReturn;
  let aMyStateData = oStateData.filter((oState) => {
    return oState.abbreviation.toUpperCase() === sState.toUpperCase();
  });

  if(aMyStateData.length > 0) {
    let oState = aMyStateData[0];
    oReturn = {
      lat: oState.latitude,
      lng: oState.longitude
    }
  } else {
    // Middle of the USA coordinates.
    oReturn = {
      lat: 40.205755,
      lng: -101.603553
    }
  }
  return oReturn;
}


/**
 * Updates the bounds of the map per specified marker positions
 */
async function updateBounds(myLatlng = null) {
  let nZoomOverride;

  if (oGoogle) {

    // If a myLatlng was passed in, lets use it here to set the map's center.
    if (myLatlng) {
      map.setCenter(myLatlng);
    } else {
      // Create new bounds.
      let bounds = new oGoogle.maps.LatLngBounds();

      // Loop through the markers and extend the current bounds by the marker position.
      if (aMarkers.length === 0) {
        // If we have no markers, lets set the bounds to the user marker's location/position.
        // if (oUserMarker?.position) {
        //   bounds.extend(oUserMarker.position);
        // } else {
          // UserMarker doesn't have a position, lets create a generic one.
          let oStateSelector = document.getElementById('state');
          let sSelectedState = oStateSelector.value;
          let oCenterCoords = getStateCoordinates(sSelectedState);
          nZoomOverride = 12;
          bounds.extend(oCenterCoords);
        // }
      } else {
        aMarkers.forEach((oMarker) => {
          let position = oMarker.position;
          bounds.extend(position);
        });
      }

      // Set the bounds.
      map.fitBounds(bounds);

      // Pan to bounds if we can.
      try {
        map.panToBounds(bounds);
      } catch (e) {
        console.log("Panning caught exception: ", e);
      }

      // If there are no markers, we've already set the bounds around the user, let's zoom out.
      if (aMarkers.length <= 1) {
        let nZoom = 12;
        const currentZoom = map.getZoom();
        if (nZoomOverride) {
          nZoom = nZoomOverride;
        }
        const newZoom = currentZoom - nZoom;
        map.setZoom(newZoom);
      }
    }
  }
}

/**
 * Gets form data from the specified element, returns an object with names and values.
 * @param oForm
 * @returns {{[p: string]: File | string}}
 */
function getData(oForm) {
  const formData = new FormData(oForm);
  return Object.fromEntries(formData);
}

/**
 * Searched a target container for a set of known or expected filters, and returns an appropriate array
 * containing what might be needed for a proper api location query.
 * @param {string} sTarget
 * @returns {{[p: string]: File|string}} aReturn
 */
function getMapFilters(sTarget) {
  const oFilterForm = document.getElementById(sTarget);
  return getData(oFilterForm);
}

/**
 * Given an array of locations, creates a response to be displayed
 * in the specified container.
 * @param aLocations
 * @param sResponseContainer
 */
async function updateLocationsResponse(aLocations, sResponseContainer) {
  if (aLocations) {
    let oResponseContainer = document.getElementById(sResponseContainer);
    oResponseContainer.innerText = '';
    aLocations.forEach((aLocation) => {
      const newLocationDiv = document.createElement("div")
      newLocationDiv.classList.add("accordion-item", "location")
      newLocationDiv.appendChild(locationTitle(aLocation, aLocation.amenities))
      newLocationDiv.appendChild(locationPanel(aLocation, aLocation.amenities))
      document.getElementById(sResponseContainer).appendChild(newLocationDiv)
    });
  }
}

/**
 * Given a location, creates a marker based on the group and type of the location.
 * @param location
 * @returns {*}
 */
function makeMarkerByLocation(location) {
  let group = location.group;
  let type = location.type;
  let innerGlyph;
  let oReturn;

  switch (group) {
    case  "P":
      innerGlyph = document.createElement("img");
      innerGlyph.src = getPTPIconFromType(type);
      innerGlyph.width = 40;
      innerGlyph.height = 30;
      break;

    case "R":
    default:
      innerGlyph = document.createElement("img");
      innerGlyph.src = getRoadysIconFromType(type);
      innerGlyph.width = 33;
      innerGlyph.height = 28;
      break;
  }

  oReturn = new AME({
    position: new oGoogle.maps.LatLng(location.latitude, location.longitude),
    map,
    title: location.location_name,
    content: innerGlyph
  });

  return oReturn;
}

/**
 * Adds markers to the map for each location - and also to our global markers array, so that we can keep
 * track of, remove, and modify them as needed.
 * @param aLocations
 * @returns {Promise<void>}
 */
async function addMarkersForLocations(aLocations) {
  const sWebRoot = PRWebRoot();
  if (oGoogle) {
    aLocations.forEach((l, index) => {
      //const address = await getDataFromAPI('location/'+l.location_id)
      let icon = false;

      switch (l.type) {
        case "R":  // Truck Stop
        case "F":  // Fuel Stop
          icon = new oGoogle.maps.MarkerImage(sWebRoot + "/assets/img/map_icons/icon_gmaps_redr.png", null, null, null, new oGoogle.maps.Size(30, 30));
          break;

        case "S":  // Truck Stop / Service Center
          icon = new oGoogle.maps.MarkerImage(sWebRoot + "/assets/img/map_icons/icon_gmaps_blur.png", null, null, null, new oGoogle.maps.Size(30, 30));
          break;

        case "V":  // Service Center
          icon = new oGoogle.maps.MarkerImage(sWebRoot + "/assets/img/map_icons/icon_gmaps_blkr.png", null, null, null, new oGoogle.maps.Size(30, 30));
          break;

        case "C":  // C-Store
          icon = new oGoogle.maps.MarkerImage(sWebRoot + "/assets/img/map_icons/icon_gmaps_yelr.png", null, null, null, new oGoogle.maps.Size(30, 30));
          break;

        // DFS or any other, we don't want to see on the map, so just in case, put this here.
        case "D":
        default:
          icon = null;
      }

      const locLink = "https://www.google.com/maps/dir/Current+Location/" + l.address + "+" + l.city + "+" + l.state + "+" + l.zip
      let numphone = l.phone.replace(/[^0-9]/g, '');

      let AdvancedMarkerElement = AME;
      let PinElement = PE;

      let marker;

      if (icon !== false) {
        let infoWindow = new oGoogle.maps.InfoWindow;

        marker = makeMarkerByLocation(l);
        marker.addListener("click", () => {
          infoWindow.close();
          infoWindow.setContent("<div class='map_bubble'><strong>" + l.location_name + "</strong></a><br>" + l.city + ", " + l.state + "<br><a href='tel:+1" + numphone + "'>" + l.phone + "</a><br><strong><a href='" + locLink + "' target='_blank'>Get Directions &#8599;</a></strong></div>");
          let myMap = marker.map ?? marker.getMap();
          infoWindow.open(myMap, marker);
        });

        // Save the marker to the global scope so that we can play with them later.
        aMarkers.push(marker);
      }
    })
  }
}

/**
 * Removes the previous markers on the map, and clears the global marker array.
 * @returns {Promise<void>}
 */
async function removePreviousMarkers() {
  if (Array.isArray(aMarkers) && aMarkers.length > 0) {
    aMarkers.forEach((oPreviousMarker) => {
      oPreviousMarker.setMap(null);
    });
  }
  aMarkers = [];
}

/**
 * Updates the existing markers, removing the old ones and replacing them with the new ones.
 * @param UserLocation
 * @param aLocations
 * @param map
 * @returns {Promise<void>}
 */
async function updateMapMarkers(UserLocation, aLocations, map) {
  await removePreviousMarkers().then(async () => {
    // If we already have a user marker, use that otherwise, refresh and get what we need.
    let myLatlng;
    if (oUserMarker?.position) {
      myLatlng = oUserMarker.position;
    } else {
      try {
        await UserLocation.initialize().then(() => {
          myLatlng = UserLocation.myLatlng;

          oUserMarker = new oGoogle.maps.Marker({
            position: myLatlng,
            map,
            title: "You",
          });
        })
      } catch (e) {
        console.log("User location not found, using generic data.");
      }
    }

    // Update the markers for the locations, and then set the bounds.
    await addMarkersForLocations(aLocations).then(async () => {
      await updateBounds();
    });
  })
}

/**
 * Updates the map message based on the locations listed.
 * @param aLocations
 * @returns {Promise<void>}
 */
async function displayMapResultsMessage(aLocations) {
  let sContent,
      sType,
      sPluralizer = 's';

  if (aLocations.length > 0) {
    if (aLocations.length === 1) {
      sPluralizer = '';
    }
    sContent = '<div class="bg-light text-black p-3 mb-2">Displaying ' + aLocations.length + ' location' + sPluralizer + '.</div>';
    sType = 'html'
  } else if (aLocations.length === 0) {
    sContent = '<div class="bg-warning text-black p-3 mb-2">No matching locations found.</div>';
    sType = 'html';
  } else {
    sContent = '';
    sType = 'text';
  }
  setContainerContent('map_message', sContent, sType);
}

/**
 * Updates the state select picker based on the states that we have available.
 * Basically just removes the states from the picker if we don't have a location
 * in that state.
 */
function updateStateSelector() {
  if (aAllStates) {
    let oStatePicker = document.getElementById("state");
    for (let i = 0; i < oStatePicker.length; i++) {
      if (oStatePicker.options[i].value !== 'all' && !aAllStates.includes(oStatePicker.options[i].value)) {
        oStatePicker.options.remove(i);
      }
    }
  }
}

/**
 * Updates map and response with filter
 * @param {string} sMapFilterContainer
 * @param {string} sResponseContainer
 * @returns {Promise<void>}
 */
async function updateMapFilters(sMapFilterContainer, sResponseContainer) {
  setContainerContent('map_message', '<div class="bg-success text-white p-3 mb-2">Updating map...</div>', 'html');
  const oFilters = getMapFilters(sMapFilterContainer)
  getLocationsByFilter(UserLocation, oFilters).then(async (aLocations) => {
    await updateMapMarkers(UserLocation, aLocations, map);
    updateLocationsResponse(aLocations, sResponseContainer).then(() => {
        displayMapResultsMessage(aLocations);
    });
  });
}

/**
 * Allow this function to be called externally by attaching it to the window object.
 * @type {(function(string, string): Promise<void>)|*}
 */
window.updateMapFilters = updateMapFilters;

/**
 * Updates a location profile on the locations.html page.
 * @param aLocationData
 * @returns {Promise<void>}
 */
async function updateLocationProfile(aLocationData) {
  if (aLocationData) {
    console.log("updateLocationProfile: we have data: ", aLocationData);

    // Location name
    const sLocationName = aLocationData['location_name'];

    // Set up the address information.
    const sAddressLine1 = aLocationData['address'];
    const sAddressLine2 = aLocationData['city'] + ', ' + aLocationData['state'] + ' ' + aLocationData['zip'];
    const sAddressFull = sAddressLine1 + "<br />" + sAddressLine2;

    // Set up phone number.
    const sPhoneNumber = aLocationData['phone'];

    // Create the Directions button.
    const locLink = "https://www.google.com/maps/dir/Current+Location/" + aLocationData['address'] + "+" + aLocationData['city'] + "+" + aLocationData['state'] + "+" + aLocationData['zip'];
    const locationNavButton = document.createElement("a")
    const locationNavIcon = document.createElement("i")
    locationNavButton.setAttribute("href", locLink);
    locationNavButton.setAttribute("target", "_blank");
    locationNavButton.setAttribute("role", "button");
    locationNavButton.appendChild(document.createTextNode('Directions '));
    locationNavButton.classList.add("btn", "btn-primary")
    locationNavIcon.classList.add("fas", "fa-location-arrow")
    locationNavButton.appendChild(locationNavIcon)
    locationNavButton.classList.add('float-end');

    // Get the multiplier data out as well.
    const sMultiplier = aLocationData['multiplier'];

    // Set up the amenities.
    let sAmenities = '';
    const aInitialAmenities = aLocationData['amenities'];
    const aCategorizedAmenities = LocationCategorizedAmenitiesDescriptions(aInitialAmenities, sMultiplier);

    // Now that we have the amenities sorted and categorized, lets do things with them.
    Object.keys(aCategorizedAmenities).forEach((sCategory) => {
      // Add the category header to the card.
      if (sCategory) {
        // Create the category-container for this amenity type.
        const oCategoryContainer = document.createElement('div');
        oCategoryContainer.classList.add('col', 'col-md-6', 'category-container');

        const categoryHeader = document.createElement('h3');
        categoryHeader.classList.add('mt-4', 'category-header', 'font-weight-bold');
        const categoryText = document.createTextNode(sCategory);
        categoryHeader.appendChild(categoryText);
        oCategoryContainer.appendChild(categoryHeader);

        // Loop through the amenities and make their markup.
        // Grab the amenities.
        const aAmenities = aCategorizedAmenities[sCategory];
        aAmenities.forEach((oAmenityMarkup) => {
          oCategoryContainer.appendChild(oAmenityMarkup);
        })

        // Add our category container to our amenities string, passing it through elementToHtml first.
        sAmenities += elementToHtml(oCategoryContainer);
      }
    });

    setContainerContent('location_name', sLocationName, 'text');
    setContainerContent('map', 'Unable to load map.', 'text');
    setContainerContent('location_address', sAddressFull, 'html');
    document.getElementById('location_directions_link').appendChild(locationNavButton);
    setContainerContent('location_phonenumber', sPhoneNumber, 'text');
    setContainerContent('location_amenities', sAmenities, 'html');

    await createMap(UserLocation, [aLocationData]).then(() => {
      console.log("Done loading map stuff.");
    });
  } else {
    setContainerContent('location_name', 'Error');
    setContainerContent('map', 'Unable to load map.');
    setContainerContent('location_address', 'No address found.');
    setContainerContent('location_phonenumber', 'Phone number not found.');
    setContainerContent('location_amenities', 'Amenities not found.');
  }
}

/**
 * Simply sets all the empty things to something to show that something is happening.
 * @returns {Promise<void>}
 */
async function setLocationProfileLoading() {
  setContainerContent('location_name', 'Loading...');
  setContainerContent('map', 'LOADING, PLEASE WAIT...', 'text');
}

/**
 * Get the first actual part of the path.
 * @param aPathParts
 * @returns {Promise<string>}
 */
async function findFirstPathPart(aPathParts) {
  let sFirst = '';

  for (let i = 0; i < aPathParts.length; i++) {
    // Skip blank parts.
    if (aPathParts[i] === '') {
      continue;
    }

    // Skip pr- sub-folders
    if (aPathParts[i].startsWith('pr-')) {
      continue;
    }

    // Return the first part that makes it here!
    sFirst = aPathParts[i];
    break;
  }
  return sFirst;
}

/**
 * If the path includes a pr-, well set that to the webroot, otherwise, we're sending
 * blank back as the webroot.  Just a helper function to make sure that the links/assets
 * specified here in a non-handlebars file get routed properly also.
 */
function PRWebRoot() {
  let sWebRoot = '';
  let path = window.location.pathname;
  let aPathParts = path.split('/');
  for (let i = 0; i < aPathParts.length; i++) {
    if (aPathParts[i] === '') {
      continue;
    }

    if (aPathParts[i].startsWith('pr-')) {
      sWebRoot = '/' + aPathParts[i];
      break;
    }
  }

  return sWebRoot;
}

/**
 * This happens every page load.
 */
(async () => {
  // Grab the page path info.
  let path = window.location.pathname;
  let pathParts = path.split('/');
  let page = path.split("/").pop();

  // If the first path parts is a pr- sub-folder, get the next part.
  let first;
  await findFirstPathPart(pathParts).then(async (data) => {
    first = data;
  });

  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

  let nLocationId = params.id ?? null; // "some_value"

  // Do map things on certain pages only.
  if (page === 'map.html') {
    // Map page gets all locations.
    setContainerContent('map', 'LOADING, PLEASE WAIT...', 'text');
    try {
      await UserLocation.initialize()
    } catch (e) {
      console.log("User location not found, using generic data.");
    }
    await getAllLocations(UserLocation, 'map_page_results').then(async() => {
      await updateStateSelector();
    });
  } else if (page === 'location.html' || first === 'location') {
    // If nLocationId is not defined from our path pulling up above, see if we've set it in the
    // window object previously, as in from our build location files script. Location pages
    // already have this set up for them that way.
    if (!nLocationId) {
      if (window.nLocationId) {
        nLocationId = window.nLocationId;
      }
    }

    if (nLocationId) {
      await setLocationProfileLoading().then(async() => {
        await getSingleLocation(nLocationId).then(async(oResult) => {
          const aLocationData = oResult.data;
          await updateLocationProfile(aLocationData);
        });
      });
    }
  } else {
    // home page gets locations for distance listing.
    await UserLocation.initialize()
    await getLocation(UserLocation)
  }
})()
