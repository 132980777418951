//
// banner.js
// Top of page banner
//
import $ from "jquery";

$(document).ready(function() {	
	// Phone formatting as you type
	$("input.phone").keyup(function() {
      var val_old = $(this).val();
      var val = val_old.replace(/\D/g, '');
      var len = val.length;
      if (len >= 10) val = val.substring(0, 10);
      if (len >= 1)
        val = '(' + val.substring(0);
      if (len >= 3)
        val = val.substring(0, 4) + ') ' + val.substring(4);
      if (len >= 6)
        val = val.substring(0, 9) + '-' + val.substring(9);
      if (val != val_old)
        $(this).focus().val('').val(val);
    });
	//Banner control
	var today = new Date();
	var dd = today.getDate();
	var mm = today.getMonth(); //January is 0!	
	if(mm == 0){
		$("#january_banner").show();
	}
});