import { sendDataToAPI, getDataFromAPI } from './roadys_api';
// import UserLocation from './user-location'

/**
 * Process the card registration form.
 * @param e
 * @returns {Promise<boolean>}
 */
const processRegisterForm = async (e) => {
	if (e.preventDefault) {
		e.preventDefault();
	}

	let register_form = document.getElementById('register_rewards');
	register_form.style.display = 'none';

	const result_message = document.getElementById('result_message');
	result_message.innerText = ""; //remove error message on retry

	let spinner = document.getElementById('reg_form_spinner');
	spinner.style.display = 'block';

	const formData = new FormData(e.target);

	let obj = {};
	obj.first_name = formData.get("fName");
	obj.last_name = formData.get("lName");
	obj.email = formData.get("cardEmail");
	obj.mobile_phone = numbersOnly(formData.get("cardPhone"));
	obj.card_number = formData.get("rewardsNumber");

	//check phone for validity
	let phoneValid = false;
	if (isValidPhone(obj.mobile_phone)) {
		 phoneValid = true;
	}

	//check Rewards number validity
	let numValid = false;
	if (isValidRewards(formData.get("rewardsNumber"))) {
		numValid = true;
	}

	if (numValid && phoneValid) {
		const result = await sendDataToAPI("card/" + formData.get("rewardsNumber") + "/holder",obj);
		if (result.data.hasOwnProperty('errors')) {
			result_message.innerHTML = "<div class='bg-warning text-black m-0 p-2'>" + result.data.errors[0].message + "</div>";
			register_form.style.display = 'block';
			spinner.style.display = 'none';
		} else {
			result_message.innerHTML = "<div class='bg-success text-black m-0 p-2'>Card registered successfully</div>";
			spinner.style.display = 'none';
		}
	} else {
		if (!numValid) {
			result_message.innerHTML = "<div class='bg-warning text-black m-0 p-2'>The card number you entered is not valid.</div>";
		} else {
			result_message.innerHTML = "<div class='bg-warning text-black m-0 p-2'>The phone number you entered is not valid.</div>";
		}

		register_form.style.display = 'block';
		spinner.style.display = 'none';
	}

	// You must return false to prevent the default form behavior
	return false;
}

/**
 * Validates a phone number, returns true or false.
 * @param p
 * @returns {boolean}
 */
function isValidPhone(p) {
	const phoneRe = /^[2-9]\d{2}[2-9]\d{2}\d{4}$/,
		digits = p.replace(/\D/g, "");
	return phoneRe.test(digits);
}

/**
 * Given a string, will only return the numbers from the string.
 * @param sString
 */
function numbersOnly(sString) {
		return sString.replace(/\D/g, '');
}

/**
 * Validates rewards numbers.
 * @param r
 * @returns {boolean}
 */
function isValidRewards(r) {
	let rewardsValid = false;
	if ((/^7018[0-9]{7,}/.test(r)) || (/^7036[0-9]{7,}/.test(r)) || (/^7371[0-9]{7,}/.test(r)) || (/^7871[0-9]{7,}/.test(r))) {
		rewardsValid = true;
	}
	return rewardsValid;
}

// Get path and page for page-specific parsing.
const path = window.location.pathname;
const page = path.split("/").pop();

/**
 * If we're on the "HOW IT WORKS" page, we'll do these things
 */
if (page === "rewards-how-it-works.html") {
	const register_form = document.querySelector('#register_rewards');

	// attachEvent() for IE8- support, addEventListened for modern browsers.
	if (typeof register_form.attachEvent === 'function') {
		register_form.attachEvent("submit", processRegisterForm);
	} else {
		register_form.addEventListener("submit", processRegisterForm);
	}

	/**
	 * process the balance form submission.
	 * @param e
	 * @returns {boolean}
	 */
	const processBalanceForm = (e) => {
		if (typeof e.preventDefault === 'function') {
			e.preventDefault();
		}

		const rewards_form = document.getElementById('rewards_balance');
		// rewards_form.style.display = 'none';

		const result_message = document.getElementById('rewards_result_message');
		result_message.innerText = ""; // remove error message on retry

		const spinner = document.getElementById('bal_form_spinner');
		spinner.style.display = 'block';

		// Clear the previous balance data.
		let balance_display = document.getElementById('balance_data');
		balance_display.innerText = '';

		// Get the form data, and let's do things!
		const formData = new FormData(e.target);
		if (isValidPhone(formData.get("cardPhone"))) {
			(async() => {
				const phone = numbersOnly(formData.get("cardPhone").replace(/[^0-9]/g, ''))
				let obj = {};
				obj.mobile_phone = numbersOnly(formData.get("cardPhone"));

				const chData = await sendDataToAPI("cardholder/search",obj)
				if (chData.data.hasOwnProperty('errors')) {
					result_message.innerHTML = "<div class='bg-warning text-black m-0 p-2'>" + chData.data.errors[0].message + "</div>";
					rewards_form.style.display = 'block';
				} else {
					const balanceData = chData.data.balance;

					let balance_form = document.getElementById('rewards_balance');
					balance_form.style.display = 'none';

					let balance_content = document.createElement("div"),
							usd             = document.createElement("div"),
							usdVal          = document.createElement("span"),
							pointsVal       = document.createElement("span"),
							showersVal      = document.createElement("span");


					// Set up the balance greeting.
					let segmentTitle = document.createElement('div');
					if (chData.data.first_name !== "") {
						segmentTitle.appendChild(document.createTextNode(chData.data.first_name + ", your Balance is:"));
					} else {
						segmentTitle.appendChild(document.createTextNode("Your Balance is:"));
					}

					// Set up the balance data.
					usdVal.appendChild(document.createTextNode('$' + Number((balanceData.usd).toFixed(2)).toLocaleString()));
					pointsVal.appendChild(document.createTextNode(Number((balanceData.usd * 100).toFixed(2)).toLocaleString() + " Points"));
					showersVal.appendChild(document.createTextNode(Number((balanceData.shw).toFixed(0)).toLocaleString() + " Showers"));

					// Append the separate segments to the balance display.
					balance_display.appendChild(segmentTitle);
					balance_display.appendChild(usdVal)
					balance_display.appendChild(pointsVal)
					balance_display.appendChild(showersVal);

					// Show the rewards form again.
					rewards_form.style.display = 'block';

					// Grab and update the text on the submit button to allow the user to check another card.
					let btnCheckBalance = rewards_form.querySelector('#button_check_balance');
					let btnRegisterToggle = rewards_form.querySelector('#registerToggle');
					// btnCheckBalance.innerText = 'Check Another Card Balance';
					btnCheckBalance.style.display ='none';
					btnRegisterToggle.style.display ='none';


				}
				spinner.style.display = 'none';
			})()
		} else {
			result_message.innerHTML = "<div class='bg-warning text-black m-0 p-2'>Please enter a valid phone number.</div>";
			rewards_form.style.display = 'block';
			spinner.style.display = 'none';
		}

		// You must return false to prevent the default form behavior
		return false;
	}

	const balance_form = document.querySelector('#rewards_balance');

	if (typeof balance_form.attachEvent === 'function') {
		balance_form.attachEvent("submit", processBalanceForm);
	} else {
		balance_form.addEventListener("submit", processBalanceForm);
	}
}
