
const processForm = (e) => {
    if (e.preventDefault) e.preventDefault();

    // Prepare data to send
    const data = {};
    const formElements = Array.from(form);
    formElements.map(input => {
        if ((input.type === 'radio' || input.type === 'checkbox') && input.checked) {
            data[input.name] = input.value
        } else if ((input.type !== 'radio' && input.type !== 'checkbox') && input.name !== '') {
            data[input.name] = input.value
        }
    });

    // console.log(formElements)
    // console.log(data)

    let xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action, true);

    // Send the collected data as JSON
    xhr.send(JSON.stringify(data));

    // Callback function
    xhr.onloadend = response => {
        if (response.target.status === 200) {
            console.log(e.target)
            e.target.querySelector('#form').classList.add("d-none")
            document.querySelector('#form_complete').classList.remove("d-none")
        }
    };
}

const form = document.querySelector('#contactForm');

if (form) {
    if (form.attachEvent) {
        form.attachEvent("submit", processForm);
    } else {
        form.addEventListener("submit", processForm);
    }
}
