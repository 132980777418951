//
// user.js
// Use this to write your custom JS
//
import './chatlio';
import './banner';
import './geo-location';
import './rewards';
import './contactForm';
// import UserLocation from './user-location'
import $ from 'jquery';

// window.UserLocation = UserLocation

// Get the current date.
const date = new Date();
// If the current date is before january 31st, 2024, show the banner.
if (date < new Date('January 31, 2024 00:00:00')) {
  $('#conference_banner').slideDown().fadeIn();
  $('#conference_banner .close').click(hide_conference_banner);
}

function hide_conference_banner() {
  $("#conference_banner").slideUp().fadeOut();
}
