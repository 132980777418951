import axios from 'axios'

axios.defaults.headers.common['Authorization'] = 'Bearer P7I6obDhgTejlPBV82F1W0cw1Qi7UeP9W40pS7d0xKB'
axios.defaults.headers.common['Content-Type'] = 'application/json'

export const getDataFromAPI = async path => {
  const url =
    'https://' +
    process.env.ROADYS_API_BASE +
    '/' +
    path

  const response = await axios
    .get(url)
    .then(response => {
      return response
    })
    .catch(function (error) {
      console.log(error.response.data)
      return {}
    })

  if (response.status === 200 && response.data !== null) {
    return {
      status_type: response.status,
      data: response.data,
    }
  }
}

export const sendDataToAPI = async (path,data) => {
  const url =
    'https://' +
    process.env.ROADYS_API_BASE +
    '/' +
    path

    //console.log(data);

  const response = await axios
    .post(url, data)
    .then(response => {
      return response
    })
    .catch(function (error) {
      return error.response
    })

    return {
      status_type: response.status,
      data: response.data,
    }

}